const frameDeformation = {
  constructor() {
    this.isOpened = false;
    this.refresh();

    const frameNameBar = document.querySelector('.frame-name').children;
    for (let i = 0, len = frameNameBar.length; i < len; i += 1) {
      this.count = 0;
      frameNameBar[i].addEventListener('transitionend', event => {
        frameDeformation.count += 1;
        if (frameNameBar.length === frameDeformation.count) {
          event.target.parentNode.parentNode.classList.add('fix');
        }
      });
    }

    this.frame = document.querySelector('.frame');
    this.frameFrame = document.querySelector('.frame-frame');
    this.frameBar = document.querySelector('.frame-bar');
    this.framePoly = document.querySelector('.frame-bar polygon');
    this.rectPoints = [
      [50, 0],
      [100, 0],
      [100, 100],
      [80, 100],
      [20, 100],
      [0, 100],
      [0, 0],
    ];
    this.hexaPoints = [
      [50, 0],
      [94, 22],
      [100, 68],
      [70, 100],
      [30, 100],
      [0, 68],
      [6, 22],
    ];
    this.getPoints(false, false);

    /*
    const trigger = document.querySelector(
      '.menu-wrap',
    );
    trigger.addEventListener('click', this.frameToggle);
    */
  },
  refresh() {
    this.isDesktop = window.matchMedia('(min-width: 1024px)').matches;
  },
  frameToggle() {
    frameDeformation.getPoints(true, !frameDeformation.isOpened);
    if (!frameDeformation.isOpened) {
      frameDeformation.frame.classList.add('isOpen');
    } else {
      frameDeformation.frame.classList.remove('isOpen');
    }
    frameDeformation.isOpened = !frameDeformation.isOpened;
  },
  getPoints(ani, isOpen) {
    const array = isOpen ? this.hexaPoints : this.rectPoints;
    const w =
      isOpen && this.isDesktop
        ? this.frameBar.clientWidth
        : this.frameFrame.clientWidth;
    const h = this.frameFrame.clientHeight;

    let points = '';
    let x = 0;
    let y = 0;

    for (let i = 0, len = array.length; i < len; i += 1) {
      x = (w * array[i][0]) / 100 + 0.5;
      if (this.isDesktop && isOpen) {
        x += window.innerWidth / 2;
      }
      y = (h * array[i][1]) / 100 + 0.5;
      points += `${x},${y} `;
    }

    if (!ani) {
      this.framePoly.setAttribute('points', points);
    } else {
      anime({
        targets: this.framePoly,
        points,
        easing: 'easeOutCubic',
        duration: isOpen ? 800 : 400,
      });
    }
  },
};

function setFrameDeformation() {
  if (document.querySelector('.frame-bar') !== null) {
    let resizeTimer;

    frameDeformation.constructor();
    window.addEventListener('resize', () => {
      if (resizeTimer !== false) clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        frameDeformation.refresh();
        frameDeformation.getPoints(false, frameDeformation.isOpened);
      }, 200);
    });
  }
}

export default function navigation() {
  const idRegexp = new RegExp(/^[a-zA-Z]+[\w|\-|:|.]+$/);
  const currentAnchor = window.location.href.split('#');

  const vueHub = new Vue({
    data: {
      isShow: false,
    },
  });

  // eslint-disable-next-line no-new
  new Vue({
    el: '.menu',
    computed: {
      isShow: {
        get() {
          return vueHub.isShow;
        },
        set(bool) {
          vueHub.isShow = bool;
        },
      },
    },
    methods: {
      toggleMenu() {
        this.isShow = !this.isShow;
      },
    },
  });
  // eslint-disable-next-line no-new
  new Vue({
    el: '.toggle',
    computed: {
      isShow: {
        get() {
          return vueHub.isShow;
        },
        set(bool) {
          vueHub.isShow = bool;
        },
      },
    },
    watch: {
      isShow() {
        frameDeformation.isOpened = !this.isShow;
        frameDeformation.frameToggle();
      },
    },
    mounted() {
      setFrameDeformation();
    },
  });
  $(window).on('unload', () => {
    if (vueHub.isShow) {
      vueHub.isShow = false;
    }
  });
  $(document).on('click', 'a', event => {
    const $target = $(event.currentTarget);
    const thisAnchor = event.currentTarget.href.split('#');

    if (vueHub.isShow) {
      if ($target.closest('.toggle').length !== 0) {
        if (
          currentAnchor[0] === thisAnchor[0] &&
          thisAnchor.length > 1 &&
          idRegexp.test(thisAnchor[1])
        ) {
          vueHub.isShow = false;
        }
      }
    }
  });
}
