import { scrollMagic, inview } from './common';
import splashScreen from './splashScreen';
import artistData from './artistData';
import navigation from './navigation';

scrollMagic.enabled(false);
splashScreen(() => {
  function readyFrame() {
    document.querySelector('.frame').classList.add('js-ready');
  }
  function readyContent() {
    document.querySelector('.content').classList.add('js-ready');
    document.querySelector('.menu').classList.add('js-ready');
  }
  function readyPcImage() {
    document
      .querySelector('.content__content-feature-image--pc')
      .classList.add('js-ready');
  }

  setTimeout(readyPcImage, 100);
  setTimeout(readyFrame, 850);
  setTimeout(readyContent, 900);
  scrollMagic.enabled(true);
  scrollMagic.update(true);
});
$('.js-inview').each((index, element) => {
  inview(element, 'in-view');
});
artistData();
navigation();
