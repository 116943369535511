export default function splashScreen(callback = $.noop) {
  const $splash = $('#Modal__01');
  const $closeButton = $splash.find('.modal__btn');

  $closeButton.one('click', () => {
    $(window).scrollTop(0);
    $splash.fadeOut(500, () => {
      $splash.remove();
      callback();
    });
  });
}
