import { inview } from './common';

const VuejsMixinFilterDatetime = {
  filters: {
    datetime(value) {
      if (!value) return value;
      const dateStr = value.replace(/\./g, '/');
      const dateObject = new Date(dateStr);
      const dateYear = String(dateObject.getFullYear());
      const dateMonth = String(dateObject.getMonth() + 1).padStart(2, '0');
      const dateDate = String(dateObject.getDate()).padStart(2, '0');

      if (
        Number.isNaN(dateYear) ||
        Number.isNaN(dateMonth) ||
        Number.isNaN(dateDate)
      ) {
        return value;
      }
      return `${dateYear}-${dateMonth}-${dateDate}`;
    },
  },
};
const VuejsMixinFilterPrice = {
  filters: {
    price(value) {
      const formatter = new Intl.NumberFormat('ja-JP');

      return formatter.format(Number(value));
    },
  },
};
const VuejsMixinDirectiveInview = {
  directives: {
    inview: {
      inserted(element) {
        element.classList.add('js-inview');
        inview(element, 'in-view');
      },
    },
  },
};

export {
  VuejsMixinFilterDatetime,
  VuejsMixinFilterPrice,
  VuejsMixinDirectiveInview,
};
