import { AIP } from './common';
import { VuejsMixinFilterDatetime } from './VuejsMixins';
import scrollFix from './scrollFix';

const newsModal = new Vue({
  mixins: [VuejsMixinFilterDatetime],
  el: '#Modal__02',
  data: {
    isShow: false,
    date: '',
    title: '',
    article: '',
  },
  watch: {
    isShow() {
      if (this.isShow) {
        scrollFix.enable();
      } else {
        scrollFix.disable();
      }
    },
  },
  methods: {
    open(idNumber) {
      $.ajaxQueue({
        type: 'GET',
        url: `${AIP.info}/${idNumber}`,
        dataType: 'jsonp',
        jsonpCallback: 'callback',
      }).done(data => {
        this.date = data.date;
        this.title = data.title;
        this.article = data.article.replace(
          /src="\//g,
          'src="https://www.sonymusic.co.jp/',
        );
        this.$nextTick(() => {
          this.isShow = true;
        });
      });
    },
    close() {
      this.isShow = false;
    },
  },
});

export default newsModal;
