import { AIP } from './common';
import {
  VuejsMixinFilterDatetime,
  VuejsMixinFilterPrice,
  VuejsMixinDirectiveInview,
} from './VuejsMixins';
import newsModal from './newsModal';
import mediaModal from './mediaModal';

export default function artistData() {
  // eslint-disable-next-line no-new
  new Vue({
    mixins: [VuejsMixinFilterDatetime, VuejsMixinDirectiveInview],
    el: '#News',
    data: {
      news: [],
      totalCount: 0,
    },
    computed: {
      remainCount() {
        return this.totalCount - this.news.length;
      },
      isRemain() {
        return this.remainCount > 0;
      },
    },
    created() {
      $.ajax({
        type: 'GET',
        url: `${AIP.info}/start/0/count/2/callback/mnj_info`,
        dataType: 'jsonp',
        jsonp: false,
        jsonpCallback: 'mnj_info',
      }).done(data => {
        if ('items' in data && 'total_count' in data && data.total_count > 0) {
          data.items.forEach(element => {
            this.news.push({
              id: element.id,
              title: element.title,
              date: element.date,
            });
          });
          this.totalCount = data.total_count;
        }
      });
    },
    methods: {
      newsModal(idNumber) {
        newsModal.open(idNumber);
      },
      moreRead() {
        const readItemIndex = this.news.length;
        const readItemNumber = this.remainCount >= 3 ? 3 : this.remainCount;

        $.ajax({
          type: 'GET',
          url: `${AIP.info}/start/${readItemIndex}/count/${readItemNumber}/callback/mnj_info${readItemIndex}`,
          dataType: 'jsonp',
          jsonp: false,
          jsonpCallback: `mnj_info${readItemIndex}`,
        }).done(data => {
          if (
            'items' in data &&
            'total_count' in data &&
            data.total_count > 0
          ) {
            data.items.forEach(element => {
              this.news.push({
                id: element.id,
                title: element.title,
                date: element.date,
              });
            });
          }
        });
      },
    },
  });

  // eslint-disable-next-line no-new
  new Vue({
    mixins: [VuejsMixinFilterDatetime, VuejsMixinDirectiveInview],
    el: '#Media',
    data: {
      tv: [],
      radio: [],
      magazine: [],
      web: [],
    },
    created() {
      $.ajax({
        type: 'GET',
        url: `${AIP.media}/tv/start/0/count/10/callback/mnj_media_tv`,
        dataType: 'jsonp',
        jsonp: false,
        jsonpCallback: 'mnj_media_tv',
      }).done(data => {
        if ('items' in data && 'total_count' in data && data.total_count > 0) {
          data.items.tv.forEach(element => {
            this.tv.push({
              type: 'tv',
              date: element.date,
              media: element.media,
              program: element.program,
              note: element.note,
            });
          });
        }
      });
      $.ajax({
        type: 'GET',
        url: `${AIP.media}/radio/start/0/count/10/callback/mnj_media_radio`,
        dataType: 'jsonp',
        jsonp: false,
        jsonpCallback: 'mnj_media_radio',
      }).done(data => {
        if ('items' in data && 'total_count' in data && data.total_count > 0) {
          data.items.radio.forEach(element => {
            this.radio.push({
              type: 'radio',
              date: element.date,
              media: element.media,
              program: element.program,
              note: element.note,
            });
          });
        }
      });
      $.ajax({
        type: 'GET',
        url: `${AIP.media}/magazine/start/0/count/10/callback/mnj_media_magazine`,
        dataType: 'jsonp',
        jsonp: false,
        jsonpCallback: 'mnj_media_magazine',
      }).done(data => {
        if ('items' in data && 'total_count' in data && data.total_count > 0) {
          data.items.magazine.forEach(element => {
            this.magazine.push({
              type: 'magazine',
              date: element.date,
              media: element.media,
              note: element.note,
            });
          });
        }
      });
      $.ajax({
        type: 'GET',
        url: `${AIP.media}/web/start/0/count/10/callback/mnj_media_web`,
        dataType: 'jsonp',
        jsonp: false,
        jsonpCallback: 'mnj_media_web',
      }).done(data => {
        if ('items' in data && 'total_count' in data && data.total_count > 0) {
          data.items.web.forEach(element => {
            this.web.push({
              type: 'web',
              date: element.date,
              media: element.media,
              note: element.note,
            });
          });
        }
      });
    },
    methods: {
      mediaModal(idNumber) {
        mediaModal.open(idNumber);
      },
    },
  });

  // eslint-disable-next-line no-new
  new Vue({
    mixins: [VuejsMixinFilterPrice, VuejsMixinDirectiveInview],
    el: '#Discography',
    data: {
      items: [],
    },
    created() {
      $.ajax({
        type: 'GET',
        url: `${AIP.discography}/start/0/count/100/callback/mnj_discography`,
        dataType: 'jsonp',
        jsonp: false,
        jsonpCallback: 'mnj_discography',
      }).done(data => {
        if ('items' in data && 'total_count' in data && data.total_count > 0) {
          const jqXHRList = [];

          data.items.forEach((currentItem, index) => {
            jqXHRList.push(
              $.ajax({
                type: 'GET',
                url: `${AIP.discography}/${currentItem.representative_goods_number}/callback/mnj_discography${index}`,
                dataType: 'jsonp',
                jsonp: false,
                jsonpCallback: `mnj_discography${index}`,
              }),
            );
          });
          $.when(...jqXHRList).done((...args) => {
            const doneArguments = jqXHRList.length > 1 ? args : [[...args]];

            doneArguments.forEach(data2 => {
              if ('items' in data2[0]) {
                const itemObj = {};
                const [{ items }] = data2;
                const discNumbers = items.discs
                  .map(currentValue => currentValue.disc_number)
                  .filter(
                    (element, index, array) => array.indexOf(element) === index,
                  )
                  .sort((a, b) => a - b);

                itemObj.type = items.type;
                itemObj.title = items.title;
                itemObj.catch_copy = items.catch_copy;
                [itemObj.comments] = items.comments;
                itemObj.image = items.image;
                itemObj.display_release_date = items.display_release_date;
                itemObj.display_goods_number = items.display_goods_number;
                itemObj.price_extax = Number.parseFloat(items.price_extax);
                itemObj.is_show_price_extax = !Number.isNaN(
                  itemObj.price_extax,
                );
                itemObj.discNumbers = discNumbers;
                itemObj.tracks = [];
                discNumbers.forEach(currentValue => {
                  const tracks = items.discs
                    .filter(element => element.disc_number === currentValue)
                    .sort(
                      (a, b) =>
                        a.contents[0].track_number - b.contents[0].track_number,
                    );

                  itemObj.tracks.push(
                    tracks.map(currentValue2 => {
                      return {
                        track: currentValue2.contents[0].track_number,
                        title: currentValue2.contents[0].title,
                      };
                    }),
                  );
                });
                this.items.push(itemObj);
              }
            });
          });
        }
      });
    },
  });

  // eslint-disable-next-line no-new
  new Vue({
    mixins: [VuejsMixinDirectiveInview],
    el: '#Profile',
    data: {
      image: '',
      body_text: '',
    },
    created() {
      $.ajaxQueue({
        type: 'GET',
        url: AIP.profile,
        dataType: 'jsonp',
        jsonp: false,
        jsonpCallback: 'callback',
      }).done(data => {
        if ('items' in data && 'body_text' in data.items) {
          const bodyText = data.items.body_text.replace(
            /src="\//g,
            'src="https://www.sonymusic.co.jp/',
          );

          this.image =
            data.items.images.length > 0
              ? data.items.images[0].image_original
              : '';
          this.body_text = bodyText;
        }
      });
    },
  });
}
