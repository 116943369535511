const artistName = 'morinana';
const apiBase = `//www.sonymusic.co.jp/json/v2/artist/${artistName}`;
const apiInfo = `${apiBase}/information`;
const apiMedia = `${apiBase}/media/category`;
const apiDiscography = `${apiBase}/discography`;
const apiProfile = `${apiBase}/profile`;
const AIP = {
  info: apiInfo,
  media: apiMedia,
  discography: apiDiscography,
  profile: apiProfile,
};
const scrollMagic = new ScrollMagic.Controller();
const inview = (inviewElement, inviewClassName) => {
  new ScrollMagic.Scene({
    triggerElement: inviewElement,
    triggerHook: 1,
    offset: 100,
    reverse: false,
  })
    .setClassToggle(inviewElement, inviewClassName)
    .addTo(scrollMagic);
};

export { AIP, scrollMagic, inview };
