import { VuejsMixinFilterDatetime } from './VuejsMixins';
import scrollFix from './scrollFix';

const mediaModal = new Vue({
  mixins: [VuejsMixinFilterDatetime],
  el: '#Modal__03',
  data: {
    isShow: false,
    date: '',
    title: '',
    detail: '',
  },
  watch: {
    isShow() {
      if (this.isShow) {
        scrollFix.enable();
      } else {
        scrollFix.disable();
      }
    },
  },
  methods: {
    open(item) {
      this.date = item.date;
      this.detail = item.note.replace(
        /src="\//g,
        'src="https://www.sonymusic.co.jp/',
      );
      switch (item.type) {
        case 'tv':
        case 'radio':
          this.title = `${item.media}「${item.program}」`;
          break;
        default:
          this.title = item.media;
      }
      this.$nextTick(() => {
        this.isShow = true;
      });
    },
    close() {
      this.isShow = false;
    },
  },
});

export default mediaModal;
